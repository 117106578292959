<section class="section " id="faq">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">Perguntas Frequentes</h1>
        <div class="section-title-border mt-3"></div>
        <p class="section-subtitle text-muted text-center font-secondary pt-4">...</p>
      </div>
    </div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Como posso me associar à CredBrasil?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Para se associar à CredBrasil, basta seguir o processo de cadastro disponível em nosso site ou visitar uma de nossas agências.</p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Quais são os benefícios de ser um cooperado?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Como cooperado, você terá voz nas decisões, acesso a serviços financeiros exclusivos e participará dos resultados da cooperativa.</p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Como recebo assessoria para escolher o melhor plano de investimento?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Nossos consultores financeiros estão à disposição para oferecer assessoria personalizada e ajudar você a escolher o plano de investimento que melhor atende às suas necessidades.</p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Quanto tempo leva para atingir meus objetivos financeiros?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>O tempo para atingir seus objetivos financeiros pode variar conforme o plano escolhido e o valor investido. Nossos consultores ajudam a criar um plano realista e alcançável.</p>
    </mat-expansion-panel>
  </mat-accordion>
    <div class="col-lg-12 text-center">
      <a href="javascript: void(0);" class="btn btn-primary waves-light waves-effect mt-5">Veja Mais <i
        class="mdi mdi-arrow-right"></i> </a>
    </div>
  </div>

</section>
